<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/profile"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.profileNotifications.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="onMarkAllAsRead">
            <ion-icon
              slot="icon-only"
              :icon="icons.checkmarkDoneOutline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="notification in notifications"
        :key="notification.id"
        @click="onSelect(notification.notification)"
        button
      >
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
              <ion-icon
                :icon="icons[notification.notification.notificationType.icon]"
                :style="{
                  color: notification.notification.notificationType.color,
                }"
                size="large"
              ></ion-icon>
            </ion-col>
            <ion-col>
              <ion-card-subtitle>
                {{ formatDate(notification.notification.created) }}
              </ion-card-subtitle>
              <ion-card-title>
                {{
                  $t(
                    `notifications.${notification.notification.notificationType.name}`,
                    notification.notification.content
                  )
                }}
              </ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="notifications.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonProgressBar,
  IonBackButton,
  IonIcon,
  IonButton,
  IonCol,
  IonRow,
  toastController,
} from '@ionic/vue';
import {
  checkmarkDoneOutline,
  helpBuoyOutline,
  peopleOutline,
  fileTrayOutline,
  clipboardOutline,
  calendarOutline,
} from 'ionicons/icons';

import ODNNoContent from '@c/odn-no-content.vue';

import APIService from '@s/api.service';

export default {
  name: 'ProfileNotifications',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonProgressBar,
    IonBackButton,
    IonIcon,
    IonButton,
    IonCol,
    IonRow,
    ODNNoContent,
  },
  data() {
    return {
      notifications: [],
      filters: {
        sort: 'notification.created',
        direction: 'desc',
      },
      page: 1,
      limit: 10,
      disableInfiniteScrolling: true,
      loading: true,
      icons: {
        checkmarkDoneOutline,
        headset: helpBuoyOutline,
        'account-multiple': peopleOutline,
        'clipboard-text-outline': fileTrayOutline,
        'clipboard-check-outline': clipboardOutline,
        'calendar-plus': calendarOutline,
        'calendar-account-outline': calendarOutline,
        'calendar-multiple': calendarOutline,
      },
    };
  },
  computed: {
    ...mapState('auth', ['authData']),
  },
  async ionViewDidEnter() {
    this.fetchData();
    this.fetchNotifications();
  },
  ionViewDidLeave() {
    this.notifications = [];
    this.page = 1;
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('notifications', [
      'fetchNotifications',
      'markNotificationAsRead',
      'markAllNotificationsAsRead',
    ]),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    getPath({ resources, notificationType }) {
      if (!notificationType.appPath) return null;
      let path = notificationType.appPath;
      for (const resource in resources) {
        path = path.replace(
          new RegExp(`:${resource}`, 'g'),
          resources[resource]
        );
      }
      return path;
    },
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get(
          `/users/${this.authData.userId}/notifications?unread=true`,
          {
            page: this.page,
            limit: this.limit,
            sort: this.filters.sort,
            direction: this.filters.direction,
          }
        );

        if (clear) {
          this.notifications = [];
        }

        this.notifications = this.notifications.concat(response.data.items);

        if (this.notifications.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.notifications.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    onSelect(notification) {
      const path = this.getPath(notification);
      if (path) {
        this.markNotificationAsRead(notification.id);
        this.$router.push(path);
      }
    },
    onMarkAllAsRead() {
      this.markAllNotificationsAsRead();
      this.fetchData(true);
    },
  },
};
</script>
